export default {
  "LanguageName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español (ES)"])},
  "logoFileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logo-sagrada-artisans-en-US.png"])},
  "logoAltText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sagrada Artesanos logo"])},
  "GetTheGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Consigue el juego!"])},
  "ChooseOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige una opción"])},
  "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver"])},
  "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
  "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
  "emailAddressPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artesano, introduce tu dirección de correo electrónico"])},
  "emailHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nunca lo compartiremos con nadie, ni lo incluiremos en ninguna lista."])},
  "WaitingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un momento..."])},
  "Error1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oh no, ¡algo ha fallado! Prueba de nuevo."])},
  "Error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Algo ha vuelto a fallar! Contacta con nuestro servicios de asistencia."])},
  "WindowInstructions1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["¡El vitral único en su clase llamado \"", _interpolate(_list(0)), "\" es todo tuyo!"])},
  "WindowInstructions2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Revisa</strong> tu correo, deberías tenerlo allí. Asegúrate de <strong>revisar</strong> también tu carpeta de correo no deseado."])},
  "SendItAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar de nuevo"])},
  "ReturnToTheGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver al juego"])},
  "EnvelopeS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre S"])},
  "unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["desbloquear"])},
  "colorColumn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Columna de ", _interpolate(_list(0))])},
  "all6ColorSpacesConstructed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["¡Las 6 casillas ", _interpolate(_list(0)), " construidas!"])},
  "noColorSpacesConstructed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Sin casillas ", _interpolate(_list(0)), " construidas"])},
  "Red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rojas"])},
  "Yellow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amarillas"])},
  "Green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verdes"])},
  "Blue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["azules"])},
  "Purple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moradas"])},
  "return": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siga con lo suyo, artesano."])},
    "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿No tiene trabajo que hacer?"])}
  },
  "download": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargando tu vitral..."])},
    "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A saber qué será eso de descargar... ¡Yo he venido aquí a hacer vidrieras!"])},
    "instructions1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Revisa</strong> tu carpeta de descargas."])},
    "instructions2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Imprime</strong> una copia del vitral para cada jugador."])},
    "instructions3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Juega</strong> este vitral atendiendo a las reglas nuevas expuestas al principio de la página."])}
  },
  "envelopeD": {
    "step1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Aah, veo que no se ha podido resistir!"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bueno, ya que le tengo aquí, dígame...  ¿cómo va la construcción de la catedral?"])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Selecciona</strong> la opción que mejor describa tus progresos en la catedral."])},
      "progressQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo va la construcción de la catedral?"])},
      "option1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Aún no hemos empezado!"])},
      "option2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo hemos hecho, pero nos queda mucho trabajo por hacer."])},
      "option3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Ya está acabada! (6 adhesivos en cada una de las 5 columnas)"])}
    },
    "step2a": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oh, ya veo..."])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Pues qué hace aquí? ¡Tiene mucho trabajo que hacer! <br/>Y no vuelva hasta que haya avanzado algo, al menos."])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Sigue</strong> trabajando en la construcción de la catedral."])}
    },
    "step2b": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Aún no ha terminado?<br/>¡¿Y qué hace aquí, entonces?!"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De acuerdo, veamos, ¿cuánto ha conseguido avanzar?"])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Rellena</strong> a continuación el número de adhesivos que has colocado en cada columna del tablero de catedral."])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡¿Pero no me había dicho que no había terminado?!"])}
    },
    "step2c": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡¿Ha terminado?!"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Es impresionante que haya resistido la tentación! ¡Bien hecho!"])},
      "instructions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Como has completado la catedral, es el momento de ", _interpolate(_named("unlock")), " el ", _interpolate(_named("linkToEnvelopeS"))])}
    },
    "step3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Más trabajo a la vista!"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisamente, tenemos lo que necesitan los artesanos ociosos como usted... ¡Otro vitral que construir!  ¿Cómo? ¡Aquí no se pagan horas extra!"])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Introduce</strong> tu dirección de correo electrónico y te enviaremos un nuevo vitral."])}
    },
    "step4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Tu nuevo vitral ya va de camino!"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemos creado un vitral único en su clase exclusivamente para ti. ¡Ya puedes volver al trabajo!"])}
    }
  },
  "envelopeS": {
    "step1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Impresionante!"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Creía que nunca terminaría la construcción de la catedral! Es increíble que incluso haya completado el nuevo... ¿Pero cómo? ¿No sabía usted de los nuevos planos? Bueno, verá, son bastante..."])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Selecciona</strong> la opción que mejor describe tus expectativas de un reto."])},
      "WhatDifficultyLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Con qué nivel de dificultad te sientes más cómodo?"])},
      "difficultyOption1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal - Algo con lo que pueda lucirme."])},
      "difficultyOption2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Difícil - Pongamos a prueba las habilidades."])},
      "difficultyOption3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épico - Soy todo oídos."])}
    },
    "step2a": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me parece bien."])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<em>Supongo</em> que se ha ganado un merecido descanso. Puede irse, si quiere, a menos que..."])},
      "button1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Está bien, lo haré."])},
      "button2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Venga, al lío!"])}
    },
    "step2b": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un elección respetable."])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esto debería ayudarle a perfeccionar sus habilidades."])}
    },
    "step2c": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al parecer, tenemos aquí una leyenda en ciernes..."])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veamos cómo te quedas después de esto."])}
    },
    "step2": {
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Introduce</strong> tu dirección de correo electrónico y te enviaremos un nuevo vitral. Esperamos que el nivel del reto sea el adecuado para ti."])}
    }
  }
}