<template>
  
    <div >    
      <h3>      
        {{ $t('download.title') }}
      </h3>
      <p class="story">
          {{ $t('download.story') }}          
      </p>
  
      <ul class="instructions">
        <li v-html="$t('download.instructions1')">            
        </li>
        <li v-html="$t('download.instructions2')">            
        </li>
        <li v-html="$t('download.instructions3')">            
        </li>
      </ul>      
      
    </div>  
  </template>
  
  <script>  
    
  export default {
    name: 'DownloadWindow',
    components: {      
    },  

    created() {
      window.location.href = 'https://api.sagradaartisans.com/window/' + this.$route.params.code      
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h3 {
    margin-bottom: 10px;
  }
  
  </style>
  