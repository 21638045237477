export default {
  "LanguageName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English (US)"])},
  "logoFileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logo-sagrada-artisans-en-US.png"])},
  "logoAltText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sagrada Artisans logo"])},
  "GetTheGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get the game!"])},
  "ChooseOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose one"])},
  "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
  "emailAddressPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artisan, please enter your email address"])},
  "emailHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will never share your email address with anyone else, or add you to any list."])},
  "WaitingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just a moment..."])},
  "Error1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oh no, something went wrong! Try again."])},
  "Error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong again! Contact our support team."])},
  "WindowInstructions1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The one-of-a-kind window named \"", _interpolate(_list(0)), "\" is all yours!"])},
  "WindowInstructions2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Check</strong> your email, it should be on the way now. Be sure to <strong>look</strong> in your spam / junk folder, too."])},
  "SendItAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send it again"])},
  "ReturnToTheGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to the game"])},
  "EnvelopeS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envelope S"])},
  "unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unlock"])},
  "colorColumn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Column"])},
  "all6ColorSpacesConstructed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["All 6 ", _interpolate(_list(0)), " spaces constructed!"])},
  "noColorSpacesConstructed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["No ", _interpolate(_list(0)), " Spaces Constructed"])},
  "Red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Red"])},
  "Yellow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yellow"])},
  "Green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Green"])},
  "Blue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blue"])},
  "Purple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purple"])},
  "return": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carry on, Artisan."])},
    "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't you have work to do?"])}
  },
  "download": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloading your window..."])},
    "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatever downloading is supposed to mean... I'm just here to make stained glass windows!"])},
    "instructions1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Check</strong> your downloads folder."])},
    "instructions2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Print</strong> one copy of the window for each player."])},
    "instructions3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Play</strong> this window, following any new rules shown at the top of the page."])}
  },
  "envelopeD": {
    "step1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahh, I see you couldn't resist!"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Well, now that you're here, tell me...  how far along are you on completing cathedral construction?"])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Select</strong> the option that best describes your progress on the Cathedral."])},
      "progressQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How far along are you on completing cathedral construction?"])},
      "option1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We haven't started at all!"])},
      "option2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some completed, but we still have work to do."])},
      "option3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All done! (6 stickers on each of the 5 columns)"])}
    },
    "step2a": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oh, I see..."])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are you doing here, you've got work to do! <br/>Don't come back until you've made at least a little progress."])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Continue</strong> working on constructing the Cathedral."])}
    },
    "step2b": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not done?<br/>What are you doing here then!?"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok then, how much progress have you made?"])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Fill in</strong> the number of stickers you've placed so far in each column of the Cathedral Board below."])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I thought you said you weren't done!?"])}
    },
    "step2c": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You're done!?"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very impressive to resist temptation for so long, it's a good thing you did!"])},
      "instructions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Since you've completed the Cathedral, it's time to ", _interpolate(_named("unlock")), " ", _interpolate(_named("linkToEnvelopeS"))])}
    },
    "step3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More work, coming right up!"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have just the thing for distractable artisans like yourselves... another window to complete!  What? Of course you won't be paid overtime!"])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Fill in</strong> your email address and we'll send a new window to you."])}
    },
    "step4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your new window is on the way!"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We've creafted a one-of-a-kind window just for you, this should get you back on track!"])}
    }
  },
  "envelopeS": {
    "step1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressive!"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I thought you'd never finish construction on the cathedral! I can't believe you even completed the new ... what's that? You didn't know abou the new plans? Well, they're quite..."])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Select</strong> the option that best describes your apetite for a challenge."])},
      "WhatDifficultyLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What window difficulty level are you comfortable with?"])},
      "difficultyOption1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal - Something I can put my own flourishes on."])},
      "difficultyOption2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hard - Let's put these skills to the test."])},
      "difficultyOption3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Epic - Now you have my attention."])}
    },
    "step2a": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fair enough."])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I <em>suppose</em> you've earned a break. You're free to go, unless..."])},
      "button1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fine, I'll do it"])},
      "button2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bring it on!"])}
    },
    "step2b": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respectable choice."])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This should help you hone your skills."])}
    },
    "step2c": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apparently we're in the midst of a legend..."])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We'll see how you're feeling after this."])}
    },
    "step2": {
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Fill in</strong> your email address and we'll send a new window to you, hopefully it will be just the right level of challenge for you."])}
    }
  }
}