<template>
    <a class="btn btn-primary" role="button" href="https://floodgate.games/sagrada-artisans"> {{ $t('GetTheGame') }}</a>
</template>

<script>

export default {
  name: 'HomePage',
  
}
</script>