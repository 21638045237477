export default {
  "LanguageName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italiano (Italia)"])},
  "logoFileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logo-sagrada-artisans-it-IT.png"])},
  "logoAltText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo Sagrada Mastri Artigiani"])},
  "GetTheGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottieni il gioco!"])},
  "ChooseOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegline una"])},
  "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
  "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua"])},
  "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo email"])},
  "emailAddressPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mastro Artigiano, per favore inserisci la tua mail"])},
  "emailHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non condivideremo il tuo indirizzo mail con nessuno nè ti aggiungeremo ad alcuna catena di mail."])},
  "WaitingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo un attimo..."])},
  "Error1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oh no, qualcosa è andato storto! Prova ancora."])},
  "Error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualcosa è andato storto di nuovo! Contatta il servizio clienti."])},
  "WindowInstructions1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La vetrata con motivo unico \"", _interpolate(_list(0)), "\" è tutta tua!"])},
  "WindowInstructions2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Controlla</strong> la tua email, dovrebbe esserti arrivata ora. Se non la trovi, <strong>controlla</strong> anche nello spam."])},
  "SendItAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manda di nuovo"])},
  "ReturnToTheGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ritorna al gioco"])},
  "EnvelopeS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busta S"])},
  "unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sbloccare"])},
  "colorColumn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Colonna"])},
  "all6ColorSpacesConstructed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tutti e 6 ", _interpolate(_list(0)), " gli spazi costruiti!"])},
  "noColorSpacesConstructed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nessuno ", _interpolate(_list(0)), " Spazio costruito"])},
  "Red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rosso"])},
  "Yellow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giallo"])},
  "Green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verde"])},
  "Blue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blu"])},
  "Purple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viola"])},
  "return": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai avanti, Mastro Artigiano."])},
    "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai del lavoro da fare?"])}
  },
  "download": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scaricamento della tua vetrata..."])},
    "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualsiasi cosa significhi scaricare... sono qui per creare vetrate colorate!"])},
    "instructions1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Controlla</strong> nella tua cartella Download."])},
    "instructions2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Stampa</strong> una copia della vetrata per ogni giocatore."])},
    "instructions3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Gioca</strong> questa finestra, applicano le nuove regole indicate in alto sulla pagina."])}
  },
  "envelopeD": {
    "step1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahh, vedo che non resisti!"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bene, ora che sei qui, dimmi...  a che punto siete con la costruzione delle Cattedrale?"])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Seleziona</strong> l'opzione che descrive meglio il vostro progresso con la Cattedrale."])},
      "progressQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A che punto siete con il completamento della Cattedrale?"])},
      "option1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non abbiamo ancora iniziato!"])},
      "option2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In parte completata, ma abbiamo ancora del lavoro da fare."])},
      "option3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutto fatto! (6 adesivi su ciascuna delle 5 colonne)"])}
    },
    "step2a": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mmh, vedo..."])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cosa stai facendo qui, torna al lavoro! <br/>Non tornare finché non avrete fatto almeno qualche progresso."])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Continua</strong> a lavorare sulla costruzione della Cattedrale."])}
    },
    "step2b": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai ancora finito?<br/>Cosa ci fai qui allora!?"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok, ma quali pogressi avete fatto?"])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Indica</strong> il numero di adesivi che finora avete piazzato su ciascuna colonna della plancia Cattedrale."])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pensavo che avessi detto che non avevate ancora finito o no!?"])}
    },
    "step2c": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'avete completata!?"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono veramente impressionato che abbiate resistito alla tentazione così a lungo ed è un bene che l'abbiate fatto!"])},
      "instructions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dato che avete completato la Cattedrale è ora di ", _interpolate(_named("unlock")), " ", _interpolate(_named("linkToEnvelopeS"))])}
    },
    "step3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro lavoro in arrivo!"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho proprio quello che serve per dei mastri artigiani distratti come voi... un'altra vetrata da completare! Come? Certamente non sarete pagati per il lavoro extra!"])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Inserisci</strong> la tua e-mail e ti manderò una nuova vetrata."])}
    },
    "step4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua nuova vetrata è in viaggio!"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbiamo creato una vetrata con un motivo unico apposta per te, questo dovrebbe rimetterti in riga!"])}
    }
  },
  "envelopeS": {
    "step1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressionante!"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pensavo che non avreste mai terminato la costruzione della Cattedrale! Non pensavo che avreste mai completato... Volete sapere cos'è questo? Non sapevate dei nuovi piani? Beh sono abbastanza..."])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Scegliete</strong> il livello di sfida con cui volete cimentarvi."])},
      "WhatDifficultyLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con quale livelo di difficoltà volete giocare?"])},
      "difficultyOption1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normale - Qualcosa che potrei fare ad occhi chiusi."])},
      "difficultyOption2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Difficile - Interessante, ma anche no."])},
      "difficultyOption3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Epico - Ora sì che avete la mia attenzione."])}
    },
    "step2a": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non male."])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<em>Credo</em> che tu abbia diritto ad una pausa. Sei libero di andare, se proprio vuoi..."])},
      "button1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bene, lo farò"])},
      "button2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, andiamo avanti!"])}
    },
    "step2b": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scelta rispettabile."])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo dovrebbe aiutarti ad affinale le tue abilità."])}
    },
    "step2c": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A quanto pare abbiamo a che fare con una leggenda..."])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedremo come la penserai dopo questo."])}
    },
    "step2": {
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Inserisci</strong> la tua email e ti manderemo una nuova finestra nella speranza che, considerate le tue notevoli capacità, si riveli sufficientemente impegnativa."])}
    }
  }
}