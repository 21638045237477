<template>

<div class="form-group">
    <label :for="label">                   
      <i18n-t keypath="colorColumn" tag="label" :for="label">
        {{label}}  
      </i18n-t>:
    </label>
    <select 
        :value="modelValue" 
        @change="$emit('update:modelValue', $event.target.value)"
        :id="label"
        class="form-control" 
        >
      <option value=""> - {{$t('ChooseOne')}} - </option>
      <option value="6">
        <i18n-t keypath="all6ColorSpacesConstructed" tag="label" :for="label">
          {{label}}  
        </i18n-t>
      </option>      
      <option value="5">5</option>
      <option value="4">4</option>
      <option value="3">3</option>
      <option value="2">2</option>
      <option value="1">1</option>
      <option value="0">
        <i18n-t keypath="noColorSpacesConstructed" tag="label" :for="label">
          {{label}}  
        </i18n-t>
      </option>
    </select>
</div>

</template>

<script>
export default {
  name: 'ColumnProgress',

    props: ['label', 'modelValue'],
    emits: ['update:modelValue'],
}
</script>

<style scoped>
</style>
