<template>
  <img :alt="$t('logoAltText')" :src="logoFileName">
  <br/>
  <router-view></router-view>  
  <footer>
      <LocaleChanger></LocaleChanger>
  </footer>
</template>

<script>
  import LocaleChanger from './components/LocaleChanger.vue';
  
  export default {
    name: 'App',
    components: {
        LocaleChanger
    },

    data() {
      return {
        logoFileName: require('./assets/' + this.$t('logoFileName'))
      }
    }
  }
</script>

<style>

@font-face {
  font-family: 'sa-serif-extra-bold';
  src: url('./assets/sa-serif-extra-bold.otf') format('opentype');
}
@font-face {
  font-family: 'sa-serif-medium';
  src: url('./assets/sa-serif-medium.otf') format('opentype');
}

html {
  background: url(./assets/paper-background.jpg) no-repeat center center fixed;
  background-size: cover;
  
}

body { 
  background-color: inherit;
  padding: 20px;
  max-width: 500px;
  margin: 0 auto;
}

/* center the footer to the bottom of the screen */
footer {  
  height: 60px;  
  margin-top: 150px;  
}

#app {
  font-family: 'sa-serif-medium';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 30px;
}
h3, strong {
  font-family: 'sa-serif-extra-bold';
}
.btn-primary {
  background-color: #9bc47c;
  border-color: #617b4e;
}
.btn-primary:hover {
  background-color: #b0de8c;
  border-color: #9bc47c;
}
.btn-primary.disabled, .btn-primary:disabled {
  background-color: #ccc;
  border-color: #999;
}

form label {
  font-size: 1.2em;
  font-family: 'sa-serif-extra-bold';
  text-align: left;
}
.form-group label {
  text-align: left;
}

.story {
  font-style: italic;
}

.instructions {
  display: block;
  background-color: #fff;
  border: 2px solid #f79c2d;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0 10px 0;
  text-align: left;
}
.instructions li {
  margin-left: 20px;
}

.instructions a { 
  font-family: 'sa-serif-extra-bold';
  text-decoration: underline;
  color:  #9bc47c;
}
.instructions a:hover {
  color: #b0de8c;
}

</style>
