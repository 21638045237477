<template>
  
  <div v-if="step==1">    
    <h3>      
        {{ $t('envelopeD.step1.title') }} 
    </h3>
    <p class="story">
      {{ $t('envelopeD.step1.story') }}
    </p>

    <ul class="instructions">
      <li v-html="$t('envelopeD.step1.instructions')">        
      </li>
    </ul>
    
    <form class="form">    
      <div class="form-group">
        <label for="progressSelection"> 
          {{ $t('envelopeD.step1.progressQuestion') }}                   
        </label>
        <select v-model="progressSelection" class="form-control" id="progressSelection">
          <option value="0"> - {{ $t('ChooseOne') }} - </option>
          <option value="1">{{ $t('envelopeD.step1.option1') }}</option>
          <option value="2">{{ $t('envelopeD.step1.option2') }}</option>
          <option value="3">{{ $t('envelopeD.step1.option3') }}</option>
        </select>
      </div>
    </form>
    <button :disabled="progressSelection == 0" class="btn btn-primary mb-2" @click="step+=1">{{ $t('Continue') }}</button>
  </div>

  <div v-if="step == 2">
    <div v-show="progressSelection == 1">
      <h3>        
        {{ $t('envelopeD.step2a.title') }}       
      </h3>
      <p class="story" v-html="$t('envelopeD.step2a.story')">        
      </p>
      <ul class="instructions">
        <li v-html="$t('envelopeD.step2a.instructions')"> 
        </li>
      </ul>  

    </div>

    <div v-show="progressSelection == 3">
      <h3>        
        {{ $t('envelopeD.step2c.title') }}
      </h3>      
      <p class="story">
        {{ $t('envelopeD.step2c.story') }}
      </p>    
      <ul class="instructions">                
        <i18n-t keypath="envelopeD.step2c.instructions" tag="li">
          <template v-slot:unlock>
            <strong>{{ $t('unlock') }}</strong>
          </template>
          <template v-slot:linkToEnvelopeS>
            <a :href="urlToEnvelopeS">{{ $t('EnvelopeS') }}</a>
          </template>          
        </i18n-t>
      </ul>
    </div>  
      
    <div v-show="progressSelection == 2">
      <h3 v-html="$t('envelopeD.step2b.title')">
             
      </h3>
      <p class="story">        
        {{ $t('envelopeD.step2b.story') }}
      </p>
      <ul class="instructions">
        <li v-html="$t('envelopeD.step2b.instructions')">          
        </li>
      </ul>
      <form class="form">    
        <ColumnProgress v-model="columnProgress.red" :label="$t('Red')" />
        <ColumnProgress v-model="columnProgress.yellow" :label="$t('Yellow')" />
        <ColumnProgress v-model="columnProgress.green" :label="$t('Green')" />
        <ColumnProgress v-model="columnProgress.blue" :label="$t('Blue')" />
        <ColumnProgress v-model="columnProgress.purple" :label="$t('Purple')" />
      </form>

      <p v-show="isCathedralComplete" class="text-danger">
        {{ $t('envelopeD.step2b.warning') }}
      </p>
      
      <button :disabled="isColumnProgressDisabled" class="btn btn-primary mb-2" @click="step+=1">{{ $t('Continue') }}</button>
    </div>
</div>
  
<div v-show="step == 3">
  <h3>
    {{ $t('envelopeD.step3.title') }}
  </h3>
  <p class="story">
    {{ $t('envelopeD.step3.story') }}    
  </p>
  <ul class="instructions">
    <li v-html="$t('envelopeD.step3.instructions')">      
    </li>
  </ul>
  <form class="form" v-on:submit.prevent="onSubmit">    
    <div class="form-group">
      <label for="emailAddress">            
        {{$t('emailAddress')}}:            
      </label>
      <input type="text" v-model="emailAddress" id="emailAddress" class="form-control" :placeholder="$t('emailAddressPlaceholder')">
      <small id="emailHelp" class="form-text text-muted">{{ $t('emailHelpText') }}</small>        
    </div>
  </form>  
  <button :disabled="isEmailFormDisabled" class="btn btn-primary mb-2" @click="submitForm">{{ $t('Continue') }}</button>
</div>

<div v-show="step == 4">
  <h3>
    {{ $t('envelopeD.step4.title') }}
  </h3>
  <p class="story">
    {{ $t('envelopeD.step4.story') }}    
  </p>
  <p v-show="isWaiting">
    {{ $t('WaitingMessage') }}
  </p>
  <p class="text-danger" v-show="showErrorMessage1">
    {{ $t('Error1') }}
  </p>
  <p class="text-danger" v-show="showErrorMessage2">
    {{ $t('Error2') }}
  </p>
  
  <ul class="instructions" v-show="showFinalInstructions">
    
    <i18n-t keypath="WindowInstructions1" tag="li" v-show="windowName != ''">
      <strong><em>{{windowName}}</em></strong>
    </i18n-t>

    <li v-html="$t('WindowInstructions2')"></li>
  </ul>
  
  <button class="btn btn-secondary" @click="submitForm" :disabled="isWaiting">{{ $t('SendItAgain') }}</button> <button class="btn btn-primary" @click="step+=1" :disabled="isWaiting" v-show="showFinalInstructions">{{ $t('ReturnToTheGame') }}</button>
</div>

<div v-show="step == 5">
  <h3>
    {{ $t('return.title') }}      
  </h3>
  <p class="story">
    {{ $t('return.story') }}
  </p>
</div>
</template>

<script>
import ColumnProgress from './ColumnProgress.vue'
import axios from 'axios'

export default {
  name: 'EnvelopeD',
  components: {
    ColumnProgress    
  },

  data() {
    return {
      urlToEnvelopeS: "/envelope-s",
      progressSelection: 0,
      step: 1,
      emailAddress: "",
      columnProgress: {
        red: "",
        yellow: "",
        green: "",
        blue: "",
        purple: "",
      },
      waitingForServer: false,
      windowName: "",
      errorMessage: "",
      sawErrorMessage: false
    }
  },

  methods: {
    async submitForm() {

      this.windowName = "";
      this.sawErrorMessage = this.errorMessage != "";
      this.errorMessage = "";
      this.step = 4;
      this.waitingForServer = true;

      axios.post('https://api.sagradaartisans.com/make-window', {      
        emailAddress: this.emailAddress,
          envelope: "D",
          columnProgress: this.columnProgress,
          locale: this.$i18n.locale
      })
      .then((response) => {
        
        this.waitingForServer = false;
        this.windowName = response.data;        
      })
      .catch((error) => {
        this.errorMessage = error;
        this.waitingForServer = false;
        this.windowName = "";
      });
    }
  },

  computed: {
    isCathedralComplete() {
      return this.columnProgress.red == 6 
      && this.columnProgress.yellow == 6 
      && this.columnProgress.green == 6 
      && this.columnProgress.blue == 6 
      && this.columnProgress.purple == 6;
    },
    isColumnProgressDisabled() {
      return this.isCathedralComplete || this.columnProgress.red == "" || this.columnProgress.yellow == "" || this.columnProgress.green == "" || this.columnProgress.blue == "" || this.columnProgress.purple == "";
    },
    isEmailFormDisabled() {      
      return this.emailAddress == "" || !this.isEmailValid;
    },
    isEmailValid() {  
      //eslint-disable-next-line    
      return (/^\w+([\.-]?\w+)*(\+\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.emailAddress));
    },
    isWaiting() {
      return this.waitingForServer;
    },
    showFinalInstructions() {
      return !this.waitingForServer && this.windowName != "";
    },
    showErrorMessage1() {
      return !this.waitingForServer && this.errorMessage != "" && !this.sawErrorMessage;
    },
    showErrorMessage2() {
      return !this.waitingForServer && this.errorMessage != "" && this.sawErrorMessage;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin-bottom: 10px;
}

</style>
