<template>
  
  <div v-if="step==1">    
    <h3>      
      {{ $t('envelopeS.step1.title') }}
    </h3>
    <p class="story">
      {{ $t('envelopeS.step1.story') }}      
    </p>

    <ul class="instructions">
      <li v-html="$t('envelopeS.step1.instructions')">        
      </li>
    </ul>
    
    <form class="form">    
      <div class="form-group">
        <label for="difficultySelection">          
          {{ $t('envelopeS.step1.WhatDifficultyLevel') }}
        </label>
        <select v-model="difficultySelection" class="form-control" id="difficultySelection">
          <option value="0"> - {{ $t('ChooseOne') }} - </option>
          <option value="1">{{ $t('envelopeS.step1.difficultyOption1') }}</option>
          <option value="2">{{ $t('envelopeS.step1.difficultyOption2') }}</option>
          <option value="3">{{ $t('envelopeS.step1.difficultyOption3') }}</option>
        </select>
      </div>
    </form>
    <button :disabled="difficultySelection == 0" class="btn btn-primary mb-2" @click="step+=1">{{ $t('Continue') }}</button>
  </div>

  <div v-if="step == 2">
    <div v-show="difficultySelection == 1">
      <h3>
        {{ $t('envelopeS.step2a.title') }}        
      </h3>
      <p class="story" v-html="$t('envelopeS.step2a.story')">        
        
      </p>
      <button class="btn btn-primary mb-2" @click="selectDifficulty(2)">{{ $t('envelopeS.step2a.button1') }}</button> &nbsp;
      <button class="btn btn-primary mb-2" @click="selectDifficulty(3)">{{ $t('envelopeS.step2a.button2') }}</button> &nbsp;
      <button class="btn btn-secondary mb-2" @click="step=4">{{ $t('ReturnToTheGame') }}</button>
    </div>
    
    <div v-show="difficultySelection == 2">
      <h3>        
        {{ $t('envelopeS.step2b.title') }}        
      </h3>      
      <p class="story">
        {{ $t('envelopeS.step2b.story') }}        
      </p>          
    </div>  
    
    <div v-show="difficultySelection == 3">
      <h3>        
        {{ $t('envelopeS.step2c.title') }}        
      </h3>
      <p class="story">
        {{ $t('envelopeS.step2c.story') }}        
      </p>       
    </div>
    
    <div v-show="difficultySelection != 0 && difficultySelection != 1">
      
      <ul class="instructions">
        <li v-html="$t('envelopeS.step2.instructions')">            
        </li>
      </ul>
      <form class="form" v-on:submit.prevent="onSubmit">    
        <div class="form-group">
          <label for="emailAddress">            
            {{$t('emailAddress')}}:            
          </label>
          <input type="text" v-model="emailAddress" id="emailAddress" class="form-control" :placeholder="$t('emailAddressPlaceholder')">
          <small id="emailHelp" class="form-text text-muted">{{ $t('emailHelpText') }}</small>        
        </div>
      </form>        
      <button class="btn btn-secondary mb-2" @click="step-=1">{{ $t('Back') }}</button> <button :disabled="isEmailFormDisabled" class="btn btn-primary mb-2" @click="submitForm">{{ $t('Continue') }}</button>
    </div>
</div>

<div v-show="step == 3">
  <h3>
    {{ $t('envelopeD.step4.title') }}
  </h3>
  <p class="story">
    {{ $t('envelopeD.step4.story') }}    
  </p>
  <p v-show="isWaiting">
    {{ $t('WaitingMessage') }}
  </p>
  <p class="text-danger" v-show="showErrorMessage1">
    {{ $t('Error1') }}
  </p>
  <p class="text-danger" v-show="showErrorMessage2">
    {{ $t('Error2') }}
  </p>
  
  <ul class="instructions" v-show="showFinalInstructions">
    
    <i18n-t keypath="WindowInstructions1" tag="li" v-show="windowName != ''">
      <strong><em>{{windowName}}</em></strong>
    </i18n-t>

    <li v-html="$t('WindowInstructions2')"></li>
  </ul>
  
  <button class="btn btn-secondary" @click="submitForm" :disabled="isWaiting">{{ $t('SendItAgain') }}</button> <button class="btn btn-primary" @click="step+=1" :disabled="isWaiting" v-show="showFinalInstructions">{{ $t('ReturnToTheGame') }}</button>
</div>

<div v-show="step == 4">
  <h3>
    {{ $t('return.title') }}      
  </h3>
  <p class="story">
    {{ $t('return.story') }}
  </p>
</div>
</template>

<script>

import axios from 'axios'

export default {
  name: 'EnvelopeS',
  components: {
    
  },

  data() {
    return {
      difficultySelection: 0,
      step: 1,
      emailAddress: "",     
      waitingForServer: false,
      windowName: "",
      errorMessage: "",
      sawErrorMessage: false
    }
  },

  methods: {
    selectDifficulty(difficulty) {
      this.difficultySelection = difficulty;      
    },
    async submitForm() {

      this.windowName = "";
      this.sawErrorMessage = this.errorMessage != "";
      this.errorMessage = "";
      this.step = 3;
      this.waitingForServer = true;

      axios.post('https://api.sagradaartisans.com/make-window', {            
        emailAddress: this.emailAddress,
        envelope: "S",
        difficulty: this.difficultySelection,
        locale: this.$i18n.locale
      })
      .then((response) => {
        
        this.waitingForServer = false;
        this.windowName = response.data;        
      })
      .catch((error) => {
        this.errorMessage = error;
        this.waitingForServer = false;
        this.windowName = "";
      });
    }
  },

  computed: {
    
    isEmailFormDisabled() {      
      return this.emailAddress == "" || !this.isEmailValid;
    },
    isEmailValid() {  
      //eslint-disable-next-line    
      return (/^\w+([\.-]?\w+)*(\+\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.emailAddress));
    },
    isWaiting() {
      return this.waitingForServer;
    },
    showFinalInstructions() {
      return !this.waitingForServer && this.windowName != "";
    },
    showErrorMessage1() {
      return !this.waitingForServer && this.errorMessage != "" && !this.sawErrorMessage;
    },
    showErrorMessage2() {
      return !this.waitingForServer && this.errorMessage != "" && this.sawErrorMessage;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin-bottom: 10px;
}

</style>
