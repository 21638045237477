import { createApp } from 'vue'
import * as VueRouter from 'vue-router'
import App from './App.vue'
import DownloadWindow from './components/DownloadWindow.vue'
import EnvelopeD from './components/EnvelopeD.vue'
import EnvelopeS from './components/EnvelopeS.vue'
import HomePage from './components/HomePage.vue'
import LocaleNotFound from './components/LocaleNotFound.vue'
import i18n from './i18n'

const routes = [
    { path: '/', component: HomePage },
    { path: '/download-window/:code', component: DownloadWindow},
    { path: '/envelope-d', component: EnvelopeD },
    { path: '/envelope-s', component: EnvelopeS },
    { path: '/window-d', redirect: '/envelope-d' },
    { path: '/window-s', redirect: '/envelope-s' },
    /* match a path that looks like it starts with a locale (two characters, a dash, 3 more characters), followed by a slash, followed by anything and call a function */    
    { path: '/:locale([a-z]{2}-[A-Z]{2})/:pathMatch(.*)*', redirect: to => {
      const { locale, pathMatch } = to.params
      
      if (!i18n.global.availableLocales.includes(locale)) {
          i18n.global.locale.value = "en-US"
          localStorage.setItem("locale", i18n.global.locale);
          return { status: 404, path: '/language-not-found' }
      }        
      i18n.global.locale.value = locale
      localStorage.setItem("locale", i18n.global.locale);
      
      return { path: '/' + pathMatch }
    }
  },

    { path : '/language-not-found', component: LocaleNotFound},
    { path : '/:pathMatch(.*)*', redirect: '/'}
  ]
const router = VueRouter.createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: VueRouter.createWebHistory(),
    routes, // short for `routes: routes`
})

const app = createApp(App).use(i18n)

app.use(router)
app.mount('#app')