export default {
  "LanguageName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français (FR)"])},
  "logoFileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logo-sagrada-artisans-en-US.png"])},
  "logoAltText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo Sagrada Artisans"])},
  "GetTheGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter le jeu"])},
  "ChooseOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez"])},
  "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
  "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse email"])},
  "emailAddressPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cher vitrailliste, entrez votre adresse email"])},
  "emailHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous ne partagerons jamais votre adresse à quelqu’un d’autre, et ne vous ajouterons à aucune liste."])},
  "WaitingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendez quelques instants..."])},
  "Error1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s’est produite ! Veuillez réessayer."])},
  "Error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s’est encore produite ! Contactez notre équipe d’assistance."])},
  "WindowInstructions1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Le vitrail unique « ", _interpolate(_list(0)), " » est maintenant à vous !"])},
  "WindowInstructions2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Vérifiez</strong> votre email, le message devrait arriver bientôt. Assurez-vous de <strong>regarder</strong> également dans vos boîtes de spam ou dans la corbeille."])},
  "SendItAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renvoyer"])},
  "ReturnToTheGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner au jeu"])},
  "EnvelopeS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enveloppe S"])},
  "unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["débloquer"])},
  "colorColumn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Colonne ", _interpolate(_list(0))])},
  "all6ColorSpacesConstructed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Les 6 cases de la colonne ", _interpolate(_list(0)), " sont toutes construites !"])},
  "noColorSpacesConstructed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Aucune case ", _interpolate(_list(0)), " construite"])},
  "Red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rouge"])},
  "Yellow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jaune"])},
  "Green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verte"])},
  "Blue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bleue"])},
  "Purple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["violette"])},
  "return": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuez votre travail, cher vitrailliste !"])},
    "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il ne vous reste vraiment rien à faire ?"])}
  },
  "download": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargement de votre vitrail..."])},
    "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je ne sais pas ce que « téléchargement » veut dire, enfin bon... Moi je suis juste là pour réaliser des vitraux !"])},
    "instructions1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Vérifiez</strong> votre dossier de téléchargement."])},
    "instructions2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Imprimez</strong> une copie du vitrail pour chaque joueur."])},
    "instructions3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Jouez</strong> ce vitrail, en vous assurant de suivre les nouvelles règles indiquées en haut de la page."])}
  },
  "envelopeD": {
    "step1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ah, je vois que vous n’avez pas pu résister !"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puisque vous êtes là, dites-moi... Où en êtes-vous dans la construction de la basilique ?"])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Choisissez</strong> les options qui décrivent le mieux votre progression sur la basilique."])},
      "progressQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Où en êtes-vous dans la construction de la basilique ?"])},
      "option1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On n’a pas encore commencé !"])},
      "option2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On en a fait une partie, mais il y a encore du travail à faire."])},
      "option3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout est fini ! (6 autocollants sur chacune des 5 colonnes)"])}
    },
    "step2a": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oh, je vois..."])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qu’est-ce que vous faites là alors ? Vous avez encore du travail ! <br/>Ne revenez pas avant d’avoir progressé un peu."])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Continuez</strong> à travailler sur la construction de la basilique."])}
    },
    "step2b": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce n’est pas fini ?<br/>Mais alors que faites-vous ici !?"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D’accord, quelle est donc votre progression ?"])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Remplissez</strong> le nombre d’autocollants que vous avez placé jusqu’ici dans chaque colonne du plateau Basilique."])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je pensais que vous aviez fini !?"])}
    },
    "step2c": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez terminé !?"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C’est impressionnant que vous ayez pu résister à la tentation aussi longtemps ! Et vous avez bien fait !"])},
      "instructions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Puisque vous avez complété la Basilique, le moment est venu de ", _interpolate(_named("unlock")), " l’", _interpolate(_named("linkToEnvelopeS"))])}
    },
    "step3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a encore du travail qui vous attend !"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons exactement ce qu’il faut pour les vitraillistes affirmés comme vous... un autre vitrail !  Comment ? Non, les heures supplémentaires ne seront pas payées !"])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Écrivez</strong> votre adresse mail et nous vous enverrons un nouveau vitrail."])}
    },
    "step4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nouveau vitrail vous attend !"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons créé un nouveau vitrail exprès pour vous, de quoi vous remettre dans le bain !"])}
    }
  },
  "envelopeS": {
    "step1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressionnant !"])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je pensais que vous ne finiriez jamais la basilique ! Je n’arrive pas à croire que vous avez même complété le nouveau... comment ? Vous n’étiez pas au courant du nouveau projet ? Disons qu’il est plutôt..."])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Choisissez</strong> l’option qui correspond le mieux au challenge que vous attendez."])},
      "WhatDifficultyLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quel niveau de difficulté souhaitez-vous avoir ?"])},
      "difficultyOption1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal - Un défi qui me permet de développer mes compétences."])},
      "difficultyOption2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Difficile - Je veux tester mes compétences."])},
      "difficultyOption3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épique - Voilà qui devient intéressant !"])}
    },
    "step2a": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien compris."])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J’<em>imagine</em> que vous avez mérité une pause. Vous pouvez partir, sauf si..."])},
      "button1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok, je vais le faire..."])},
      "button2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allons-y !"])}
    },
    "step2b": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C’est un choix respectable."])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceci devrait vous aider à parfaire vos compétences."])}
    },
    "step2c": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il semblerait que nous soyons en présence d’une future légende..."])},
      "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyons voir comment vous vous sentirez après ça."])}
    },
    "step2": {
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Écrivez</strong> votre adresse mail et nous vous enverrons un nouveau vitrail. Espérons que ce défi sera au bon niveau pour vous."])}
    }
  }
}