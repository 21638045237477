<template>
  
    <div >    
      <h3>      
          Hi, hello, hey there!
      </h3>
      <p class="story">
        We don't support the language you're looking for. Sorry!
      </p>
      
    </div>  
  </template>
  
  <script>  
    
  export default {
    name: 'LocaleNotFound',
    components: {      
    },  
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h3 {
    margin-bottom: 10px;
  }
  
  </style>
  